import React, { useEffect, useState } from 'react';
import Links from "../components/Footer/Links";
import '../assets/css/style-acces-rapide.css';
import { getResource } from "../service/api";

const PUBLIC_URL = process.env.PUBLIC_URL


export default function Footer() {
    const [setting, setSetting] = useState([])
    useEffect(() => {
        getResource(`settings`).then(res => {
            setSetting(res.data.data[0])
        })
    }, [])
    var ladate=new Date()
    ladate = ladate.getFullYear()
    return (
        <>
        <footer className="site-footer">
        <div className="container">
            <div className="row pb-5 pt-3">
                <div className="col-md-3 pt-5 mt-2">
                {/*white-img*/}
                    <img src={`${PUBLIC_URL}/assets/images/logo.png`} alt=" white-img footer logo" className="d-inline pb-3 footer-logo"/>
                </div>
                <div className="col-md-7">

                <Links/>
        
                    <div>
                        <p className="text-right text-white">
                        © {setting && setting.copyright_author} - {ladate}
                        </p>
                    </div>
                </div>
                <div className="col-md-2 pt-5 mt-2">
                <img src={`${PUBLIC_URL}/assets/images/europeanunion.png`} alt="footer logo" className="d-inline pb-3 footer-logo pull-right"/>
                </div>
                <small class="text-white col-lg-12 mt-3 ml-1 mr-1 mb-0 text-center"> {setting && setting.copyright}</small>
            </div>
        </div>
        <div className="flag-look-like">
            <div className="col green-block"></div>
            <div className="col yellow-block"></div>
            <div className="col red-block"></div>
        </div>
    </footer>
        </>
    )
}
