import React from 'react';
import moment from 'moment'

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

const ContentNews = ({news}) => {

return ( 
<section id="blog-single" className="pt-90 pb-120 gray-bg">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="blog-details mt-30">
                    <div className="thum">
                        <img src={`${directory_doc}/${news.fichier && news.fichier.filename}`} alt="Blog Details"/>
                    </div>
                    <div className="cont">
                        <h3>{news.title}</h3>
                        <ul>
                            <li><a href="#"><i className="fa fa-calendar"></i>{moment(news.created_at).format('DD-MMM-YYYY')}</a></li>
                            <li><a href="#"><i className="fa fa-user"></i>{`${news.users.firstname} ${news.users.lastname}`}</a></li>
                            <li><a href="#"><i className="fa fa-tags"></i>{news.modules.name}</a></li>
                        </ul>
                            <p dangerouslySetInnerHTML={ {__html:news.content && news.content} }></p>
                     
                       </div> 
                </div> 
            </div>
    
        </div>
    </div> 
</section>

     );
}
 
export default ContentNews;