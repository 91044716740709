import axios from 'axios';
import Cookies from "js-cookie";

/** Get Access Token For App */
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const access_token = Cookies.get('access_token');
axios.interceptors.request.use(async function (config) {
  if (typeof (access_token) === 'string') {
    config.headers.Authorization = `Bearer ${access_token}` ;
    config.headers['cache-control'] = `no-cache`;
    }
    return config;
}, function (err) {
    console.log('Erreur', err);
   // return Promise.reject(err);
});

/**
 *
 * @param {string} resource_url
 * @param {Array} filters
 * @param {number} limit
 */
export const getResource = (resource_url) => {
  // if (typeof (access_token) == 'undefined') {
  //   window.location.reload();
  // }
  return axios.get(resource_url, {headers: {'Content-Type': 'application/json'}})
}


/**
 *
 * @param {string} resource_url - Url for API
 * @param {object} data - Data
 * @param {object} headers
 */
export const postResource = (resource_url, data, headers) => {

  return axios.post(resource_url, data, headers)
}

/**
 *
 * @param {string} resource_url
 * @param {number} id
 * @param {object} data
 * @param {object} headers
 */
export const putResource = (resource_url, id, data, headers) => {
  return axios.put(resource_url + '/' + id, data, headers)
}

export const putResourceByUrl = (resource_url, data, headers) => {
  return axios.put(resource_url, data, headers)
}

/**
 *
 * @param {string} resource_url
 * @param {number} id
 */
export const removeResource = (resource_url, id) => {
  return axios.delete(resource_url + '/' + id)
}
