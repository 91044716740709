// import React, { useContext, useEffect, useState } from 'react';
// import { AppContext } from '../../App';
// import _ from 'lodash'

// const PUBLIC_URL = process.env.PUBLIC_URL

// const Navigation = () => {

//     const {appState, setAppState} = useContext(AppContext)
//     let {collapsed, defaultdatas:{menus}} = appState
//     const [sticky,setSticky]= useState(false)


//     const generateLink = menu =>{
//        if(menu.page_id) return `/page/${menu.pages.permalink}`
//        if(menu.target_link) {
//            if(menu.target_link.startsWith('http')) return menu.target_link
//            else return `/${menu.target_link}`
//        }
//        if(menu.module_id) return `/section/${menu.modules.name}`
//        if(menu.display_services_pages) return `/services`
//        return '/'
//     }

//     useEffect(()=>{
//         window.addEventListener('scroll', function(ev) {

//             var someDiv = window.document.getElementById('header-part');
//             var distanceToTop = someDiv.getBoundingClientRect().top;
//             if(distanceToTop <-200){
//                 setSticky(true)
//             }else{
//                 setSticky(false)

//             }
//          });
//     },[])

//     const formatChildren = (menus) => {
//         const menusMap = menus.filter(m=>m.parent_id ==null)
//         menusMap.map((mp,indexp)=>{
//             menusMap[indexp]["children"] = []
//             menus.map(mn=>{
//                 if(mn.parent_id==mp.id) 
//                 menusMap[indexp].children.push(mn)
//             })
//         })

//         return menusMap
//     }
//     menus = formatChildren(menus.data);
       
//     return ( 
//         <header id="header-part" >
//         {<div className={`navigation ${sticky?'sticky':''}`}>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <nav className="navbar navbar-expand-lg">
//                             <a className="navbar-brand" href="/">
//                                 <img src={`${PUBLIC_URL}/assets/images/logo.png`} alt="Logo" className="img-fluid site-logo"/>
//                             </a>
//                             <button onClick={()=>{setAppState({...appState, collapsed:collapsed=="on"?"off":"on"})}}  className={`navbar-toggler ${collapsed=="on"?'collapsed':'active'}`} type="button" data-toggle="collapse"
//                                     data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
//                                     aria-expanded={collapsed=="on"?"false":"true"} aria-label="Toggle navigation">
//                                 <span className="icon-bar"></span>
//                                 <span className="icon-bar"></span>
//                                 <span className="icon-bar"></span>
//                             </button>

//                              <div className={`collapse navbar-collapse sub-menu-bar collapse ${collapsed=="on"?'':'show'}`} id="navbarSupportedContent">
//                                 <ul className="navbar-nav ml-auto">
//                                     {menus && menus.map((menu) => (
//                                         <li className="nav-item" key={`parentmenu${menu.id}`}>
//                                             <a className={menu.id == 1 ? 'active' : ""} href={generateLink(menu)} 
//                                               >{menu.title}</a>
//                                             <ul className="sub-menu">
//                                                 {menu.children.map((childrens) => (
//                                                     <li key={`submenu${childrens.id}`}><a href={generateLink(childrens)} >{childrens.title}</a></li>
//                                                  ))
//                                                 }
                                               
//                                             </ul>
//                                         </li>
//                                       ))}
//                                 </ul>
//                             </div> 
//                             <div className="right-icon text-right ">
//                                 <ul>
//                                     <li><span onClick={()=>{setAppState({...appState, searchBarDisplay:true})}} id="search"><i className="fa fa-search"></i></span></li>
//                                 </ul>
//                             </div> 
//                         </nav> 
//                     </div>
//                 </div> 
//             </div> 
//         </div>}
//     </header>     );
// }
 
// export default Navigation;


import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import _ from 'lodash'
import { useParams, useLocation } from 'react-router-dom';

const PUBLIC_URL = process.env.PUBLIC_URL

const Navigation = () => {

    const {appState, setAppState} = useContext(AppContext)
    let {collapsed, defaultdatas:{menus}} = appState
    const [sticky,setSticky]= useState(false)
    // const [menu_parent,setParentMenu]= useState(menu_parent)


    const generateLink = menu =>{

        // console.log(menu.title)
       if(menu.page_id) return `/page/${menu.parents?menu.parents.title:"home"}/${menu.pages.permalink}`
  
       if(menu.module_id) return `/section/${menu.parents?menu.parents.title:"home"}/${menu.modules.name}`
       if(menu.display_services_pages) return `/content/${menu.parents?menu.parents.title:"home"}/services`
       if(menu.target_link) {
        if(menu.target_link.indexOf("http") == 0 || menu.target_link.indexOf("https") == 0)
        return PUBLIC_URL + menu.target_link
        return menu.target_link
       }
       return '/'
    }

    useEffect(()=>{
        window.addEventListener('scroll', function(ev) {

            var someDiv = window.document.getElementById('header-part');
            var distanceToTop = someDiv.getBoundingClientRect().top;
            if(distanceToTop <-200){
                setSticky(true)
            }else{
                setSticky(false)

            }
         });
    },[])
    
    const formatChildren = (menus) => {
        const menusMap = menus.filter(m=>m.parent_id ==null)
        menusMap.map((mp,indexp)=>{
            menusMap[indexp]["children"] = []
            menus.map(mn=>{
                if(mn.parent_id==mp.id) 
                menusMap[indexp].children.push(mn)
            })
        })

        return menusMap
    }
    menus = formatChildren(menus.data);   
    let location = useLocation();

    //  console.log(location.pathname)

    return ( 
        <header id="header-part">

        {<div className={`navigation ${sticky?'sticky':''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg" id="nav">
                            <a className="navbar-brand" href="/">
                                <img src={`${PUBLIC_URL}/assets/images/logo.png`} alt="Logo" className=" d-inline  footer-logo"/>
                            </a>
                            <button onClick={()=>{setAppState({...appState, collapsed:collapsed=="on"?"off":"on"})}}  className={`navbar-toggler ${collapsed=="on"?'collapsed':'active'}`} type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded={collapsed=="on"?"false":"true"} aria-label="Toggle navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>

                             <div className={`collapse navbar-collapse sub-menu-bar collapse ${collapsed=="on"?'':'show'}`} id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    {menus && menus.map((menu) => (
                                        <li className="nav-item" key={`parentmenu${menu.id}`}>
                                            <a className={(menu.title == location.pathname.split('/')[2] || location.pathname.indexOf(menu.title)!=-1 || ( menu.title=="Médiathèque" &&location.pathname.indexOf('mediatheque')!=-1 || ( menu.title=="Organe" &&location.pathname.indexOf('organe') != 1)))? 'active' : ""} href={generateLink(menu)} 
                                              >{menu.title}  </a>
                                            <ul className="sub-menu">
                                                {menu.children.map((childrens) => (
                                                    <li key={`submenu${childrens.id}`}><a href={generateLink(childrens)} >{childrens.title}</a></li>
                                                 ))
                                                }
                                               
                                            </ul>
                                        </li>
                                      ))}
                                </ul>
                            </div> 
                            <div className="right-icon text-right ">
                                <ul>
                                    <li><span onClick={()=>{setAppState({...appState, searchBarDisplay:true})}} id="search"><i className="fa fa-search"></i></span></li>
                                </ul>
                            </div> 
                        </nav> 
                    </div>
                </div> 
            </div> 
        </div>}
    </header>     );
}
 
export default Navigation;
