import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../App'

function CountNumber() {

    const { appState: { defaultdatas } } = useContext(AppContext)
    const [setting, setSetting] = useState(null)

    useEffect(() => {
        if (defaultdatas) {
            setSetting( defaultdatas.setting.data.length > 0 ? defaultdatas.setting.data[0] : null)
        }
    }, [defaultdatas])

    return (
        <div>
        <section id="les-indice">
        {setting && <div id="counter-part" className="bg_cover pb-50 job" data-overlay="8">
            <div className="container">
                <div className="row">
                    {setting.home_indicator_1 &&<div className="col-lg-3 col-sm-6">
                        <div className="single-counter text-center mt-40">
                        <span><span className="counter">{setting.home_indicator_1_value}</span>+</span>
                            <p>{setting.home_indicator_1}</p>
                        </div>
                    </div>}
                    {setting.home_indicator_2 &&<div className="col-lg-3 col-sm-6">
                        <div className="single-counter text-center mt-40">
                        <span><span className="counter">{setting.home_indicator_2_value}</span>+</span>
                            <p>{setting.home_indicator_2}</p>
                        </div>
                    </div>}
                    {setting.home_indicator_3 &&<div className="col-lg-3 col-sm-6">
                        <div className="single-counter text-center mt-40">
                        <span><span className="counter">{setting.home_indicator_3_value}</span>+</span>
                            <p>{setting.home_indicator_3}</p>
                        </div>
                    </div>}
                    {setting.home_indicator_4 &&<div className="col-lg-3 col-sm-6">
                        <div className="single-counter text-center mt-40">
                            <span><span className="counter">{setting.home_indicator_4_value}</span>+</span>
                            <p>{setting.home_indicator_4}</p>
                        </div>
                    </div>}
                </div>
            </div>
        </div>}
    </section>
        </div>
    )
}

export default CountNumber
