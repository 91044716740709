import React, { useEffect, useState, useContext } from 'react';
import Slider from "react-slick";
import { AppContext } from '../../App';
import { getResource } from '../../service/api';


const directory = process.env.REACT_APP_UPLOAD_DIRECTORY

export default function BannerSlider({ page }) {
    const { appState: { defaultdatas } } = useContext(AppContext)
    // console.log(defaultdatas[0])

    // const[sliders, setSliders] = useState([]);


    // useEffect(() => {

    //     getResource(`news?public=1&type=nouvelle`).then(res => {
    //         if(res.data.data.length>0){
    //             setSliders(res.data.data);
    //         }

    //     })

    // }, []);


    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div className="" >

            <span
                {...props}
                className={
                    "prev slick-arrow" +
                    (currentSlide === 0 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}
                aria-hidden="true"
                aria-disabled={currentSlide === 0 ? true : false}
            >
                <i class="fa fa-angle-left"></i>
            </span>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

        <div className="" >
            <span
                {...props}
                className={
                    "next slick-arrow" +
                    (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}

                aria-hidden="true"
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
            >
                <i class="fa fa-angle-right"></i>

            </span>
        </div>
    )
    const SliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay:true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        focusOnSelect: true,
        draggable: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div>
            <section id="slider-part" >
                <Slider {...SliderSettings} className="slider-active">
                    {defaultdatas && defaultdatas.news.data.filter(n => n.in_slider == 1).map(s => (
                        <div>
                            <div className="single-slider bg_cover pt-50" style={{ backgroundImage: `url(${directory + '/' + s.fichier.filename})` }} data-overlay="4" >
                                <div className="container">
                                    <div className="row">
                                        <div >
                                            <div className="slider-cont">
                                                <h3 data-animation="fadeInLeft" data-delay="1s" dangerouslySetInnerHTML={{ __html: s.title && s.title.substr(0, 60).concat('<b> ... </b>') }}></h3>
                                                {/* {s.content ? 
                                            <p data-animation="fadeInUp" data-delay="1.3s" dangerouslySetInnerHTML={ {__html:s.content.substr(0,20).concat('<b> ... </b>')} }></p>
                                            :
                                            <p data-animation="fadeInUp" data-delay="1.3s" style={{ marginBottom: '50px'}}></p>
                                            
                                            } */}

                                                <ul>
                                                    <li><a data-animation="fadeInUp" data-delay="1.6s"
                                                        className="main-btn" href={`/news/${s.permalink}`}>Lire plus</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </section>
        </div>
    )
}