import React, { useEffect, useState } from 'react';
import { getResource } from "../../service/api";
import moment from 'moment'
import {Alert} from 'react-bootstrap';


const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;


export default function ContentModuleDocument({ module }) {

    const [documents, setDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    const [query, setQuery] = useState("");


    const HandleSubmit = async(e) => {
        e.preventDefault();

        if(query) {

            await getResource(`documentations?title=${query}&module_id=${module.id}&page=${currentPage}&public=1`).then((res) => {
                setDocuments(res.data.data);
            })

        }else{

            alert('veuillez renseigner ce champ');

        }
    
    }

    const getQuery = (e) => {
        setQuery(e.target.value);
    }

    const documentData = async() => {

        await getResource(`documentations?module_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setDocuments(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })

    }

    useEffect(() => {
        documentData();

        if(query === ""){
            documentData();
        }
    }, [currentPage,query])

    return (
        <div id="simple-page" className="pt-90 pb-120 bg-white">
            <div className="container">
                <div className="row">
                <div className="col-md-6 offset-md-3 pb-40">
                    <div className="input-group mb-3 mt-4">
                        <input onChange={getQuery} type="text" className="form-control newsletter-input" placeholder={`${typeof query !== 'undefined' && query.length > 0 ? query : "Recherche un document"}`}
                            aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append">
                            <span type="" onClick={HandleSubmit} className="input-group-text site-secondary-bgcolor" id="basic-addon2"><i
                                    className="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
                </div>
              
                {
                    typeof documents !== 'undefined' && documents.length > 0 ? documents.map((doc, index) => (
                        <div className="row mt-4 border-bottom p-4" key={index}>
                            <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                                <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                                    <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(doc.created_at).format('DD.MM.YYYY')}</small>
                    
                                        {/* <small className="d-block "><b className="semibold">Type  </b> {doc.type_doc}</small>
                                        <small className="d-block text-uppercase"><b className="semibold">57</b> Lectures</small>
                                    <small className="d-block text-uppercase"><b className="semibold">684</b> Téléchargements</small> */}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7 pr-3 pb-0 bd-highlight">
                                <div className="header adapt">
                                    <h3 className="h4 semibold adapt bottom-5"><a href={`${directory_doc}/${doc.fichier.filename}`}  className="doc-title text-dark">{doc.title}</a></h3>
                                    <span className="black adapt bottom-15 adapt light text-justify doc-desc "
                                        data-keywords="&quot;&quot;">{doc.description}</span>
                                    <br />


                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 pb-0 bd-highlight`">
                                <div className="action-btn">
                                   
                                    <a href={`${directory_doc}/${doc.fichier.filename}`} className="pb-1 pr-0 btn btn-link text-uppercase font-weight-bold download">
                                        Telecharger
                            </a>
                                </div>
                            </div>
                        </div>

                    )): <Alert className="col-md-4" variant="secondary"><h3>Pas de document</h3></Alert>
                }
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
    )
}
