import React, { useContext } from 'react';
import { AppContext } from '../../App';
import Slider from "react-slick";
import '../../assets/css/weblink.css';
import '../../assets/css/style-acces-rapide.css';


const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div className="col-md-1 arrowPositionLeft survol" >

        <span
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-left fa-2x" aria-hidden="true"></i>
        </span>
    </div>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

    <div className="col-md-1 arrowPositionRight survol">
        <span
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}

            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-right fa-2x" aria-hidden="true"></i>

        </span>
    </div>
)

const Links = () => {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
        autoplaySpeed: 2000,
        focusOnSelect: true,
        draggable: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (

        <div className="lien-utlies-section pb-2 mb-3 weblink">
            <h5 className="text-center text-white">LIENS UTILES</h5>

            <Slider {...settings} style={{ height: '100px' }}>
                {defaultdatas && defaultdatas.weblinks.map(link => (
                    <div key={link.id} className="rounded border border-light text-white p-2">
                        <a className="text-white liens" href={link.access_link}>
                            <span className="lien-icon"><i className="fa fa-link"></i></span>
                            {link.name}
                        </a>
                    </div>
                ))}
            </Slider>

            <div>
                <p>
                    <h4 className="text-white title-acces text-center">Accès Rapide</h4>

                    <div className="row acces-rapide">
                        <div className="col-md-4">
                            <ul>
                                <li><a href="https://stpcnos.agriculture.gouv.bj/page/CNOS/le-secretariat-technique-permanent" className="text-white size-text">Secrétariat technique permanent</a></li>
                                <li><a href="http://stpcnos.agriculture.gouv.bj/organes" className="text-white size-text">Organes</a></li>
                                <li><a href="http://stpcnos.agriculture.gouv.bj/page/CNOS/lequipe" className="text-white size-text">L'équipe</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul>
                                <li><a href="http://stpcnos.agriculture.gouv.bj/section/home/Actualités" className="text-white size-text">Actualités</a></li>
                                <li><a href="http://stpcnos.agriculture.gouv.bj/mediatheque" className="text-white size-text">Médiathèque</a></li>
                                <li><a href="http://stpcnos.agriculture.gouv.bj/section/Documentations/Rapports" className="text-white size-text">Rapports</a></li>

                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul>
                                <li><a href="http://stpcnos.agriculture.gouv.bj/section/Documentations/Lois et Réglémentations" className="text-white size-text">Lois et Réglémentations</a></li>
                                <li><a href="http://stpcnos.agriculture.gouv.bj/section/home/Contacts" className="text-white size-text">Contact</a></li>
                                <li><a href="https://stpcnos.agriculture.gouv.bj/page/mentions-legales" className="text-white size-text">Mentions Légales</a></li>
                            </ul>
                        </div>
                    </div>
                </p>
            </div>

        </div>
    )
}

export default Links
