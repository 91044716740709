import React, {useEffect, useState } from 'react';
import {postResource} from '../../service/api';
import {Form,Col,Alert} from 'react-bootstrap';

function Newsletter() {

     const [query, setQuery] = useState("");
     const [alert, setAlert] = useState(null);
     const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);

     const Reset = () => {
         document.getElementById("query").reset();
     }

    const HandleSubmit = (e) => {
        e.preventDefault();

        console.log('rr',query);

        if(query) {

            if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(query)){

                setButton(<i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>)

                postResource('contacts', {email:query, isNewletter:1}).then(() => {

                   
                    setAlert({ success: true, message: 'Votre email a été prise en compte. Merci !!!' });
                    setButton(<i className="fa fa-paper-plane"></i>);
                    
                    

                  },() => {

                    setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
                    setButton(<i className="fa fa-paper-plane"></i>)

                  }).finally(() => {
                        setTimeout(() => {
                            setAlert(null);
                            Reset();
                            setQuery("");
                        }, 5000);
                    });
            }else{
                setAlert({ success: false, message: 'veuillez entrer une adresse email valide' });
              
            }

        }else{
            setAlert({ success: false, message: 'veuillez renseigner ce champ' });
            

        }
    
    }

    const getQuery = (e) => {
        setQuery(e.target.value);
        console.log('hhh',e.target.value)
    }

    return (
        <>
            <section id="newsletter-part">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-flex flex-row bd-highlight mb-3 mt-3">
                                <div className="p-2 bd-highlight">
                                    <h4><i className="fa fa-bullhorn site-secondary-text-color"></i></h4>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <h4 className="site-secondary-text-color">ABONNEZ VOUS</h4>
                                    <p className="text-white">
                                        Recevez les actualités du CNOS.
                                    </p>
                                    <a href='https://stpcnos.agriculture.gouv.bj/page/mentions-legales' className='text-white'> <small> Vous pouvez à tout moment vous désabonner. </small></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">   
                            {
                                alert && <Alert className="text-center" variant={alert.success ? 'success' : 'danger'} onClose={() => setAlert(null)} dismissible>
                                { alert.message }
                                </Alert>
                            }  
                            <form id="query">
                            <div className="input-group mb-3 mt-3">
                                <input  type="email" onChange={getQuery} name="email" required className="form-control newsletter-input"
                                    placeholder="Votre adresse email ici" aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"/>
                                <div className="input-group-append">
                                        <span type="submit" onClick={HandleSubmit} className="input-group-text site-secondary-bgcolor" id="basic-addon2">{button}</span>
                                </div>
                            </div>
                            </form>   
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Newsletter
