import React, { useContext } from 'react';
import Slider from "react-slick";
import { AppContext } from '../../App';
import moment from 'moment';

const directory = process.env.REACT_APP_UPLOAD_DIRECTORY

const Actualites = () => {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (

        <div className="course-slide news-slick" >

            <span
                {...props}
                className={
                    "prev slick-arrow" +
                    (currentSlide === 0 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}
                aria-hidden="true"
                aria-disabled={currentSlide === 0 ? true : false}
            >
                <i class="fa fa-angle-left"></i>
            </span>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

        <div className="course-slide news-slick" >
            <span
                {...props}
                className={
                    "next slick-arrow" +
                    (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}

                aria-hidden="true"
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
            >
                <i class="fa fa-angle-right"></i>

            </span>
        </div>
    )
    const SliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        focusOnSelect: true,
        draggable: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div>
            <section id="course-part" className="pt-50 pb-50  ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title pb-45">
                                <h5>Actualités</h5>
                            </div>
                        </div>
                    </div>

                    <Slider {...SliderSettings}>

                        {defaultdatas && defaultdatas.news.data.map(data => (

                            <div key={data.id} >
                                <div className="single-course">
                                    <div className="thum">
                                        {data.fichier && <div className="image">
                                            <img src={`${directory}/${data.fichier.filename}`} alt={data.title} />
                                        </div>}

                                    </div>
                                    <div className="cont">
                                        <a href={`/news/${data.permalink}`}>
                                            <h4>{data.title}</h4>
                                        </a>
                                        <div className="course-teacher">

                                            <div className="name">
                                                <a >
                                                    <h6><i className="fa fa-calendar"></i> Publiée le {moment(data.created_at).format('DD-MM-YYYY')}</h6>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        }

                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Actualites


