import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import { postResource } from '../../service/api'
import {
    Card,
    Form,
    Button,
    Alert
  } from 'react-bootstrap';

import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'



export default function ContentModuleContact({ module }) {

    const [contacts, setContacts] = useState([])
    const [data, setData] = useState([])
    const [contactEmail, setContactEmail] = useState([])
    const [alert, setAlert] = useState(null);
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);
    const {subject} = useParams();
    const [code, setCode] = useState(null);
    

    useEffect(() => {
         getResource(`contacts?module_id=${module.id}&public=1`).then(res => {
            setContacts(res.data.data)
        })

    }, [])

    const Reset = () => {
        document.getElementById("contact-form").reset();
    }

    const onSubmit = (event) => {
        event.preventDefault();

        setButton(<i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>);

            postResource('contactMail',data).then(() => {
                setAlert({ success: true, message: 'Votre message a été prise en compte' })
                setButton(<i className="fa fa-paper-plane"></i>)
                setCode({color:'#fff', 
                backgroundColor:'#4AA340'})

              }, () => {
                setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
                setButton(<i className="fa fa-paper-plane"></i>)
              }).finally(() => {
                setTimeout(() => {
                  setAlert(null);
                  Reset();
                  setData(null);
                }, 10000);
                
              });
        }
           


    return (
        <section id="contact-page" className="pt-30 pb-30 white-bg">
            <div className="container">
           
                <div className="row">
                    <div className="col-lg-7">
                        <div className="contact-from mt-30">
                        {
                            alert && <Alert style={code ? code : {color: 'black'}}   variant={alert.success ? 'success' : 'danger'} >
                            { alert.message }
                            </Alert>
                         }
                            <div className="section-title">
                                <h5>{subject ? subject : 'Contactez-nous'}</h5>
                            </div>
                            <div className="main-form pt-45">
                                <form id="contact-form" onSubmit={onSubmit} method="post" data-toggle="validator">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input onInput={ (e) => setData({...data,name:e.target.value})} name="name" type="text" placeholder="Votre nom"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input onInput={ (e) => setData({...data,email:e.target.value})} name="email" type="email" placeholder="Email"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input onInput={ (e) => setData({...data,sujet:e.target.value})} className="color-green" name="sujet"   type="text" placeholder="Objet"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div> 
                                        
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input onInput={ (e) => setData({...data,telephone:e.target.value})} name="phone" type="text" placeholder="Telephone"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="single-form form-group">
                                                <textarea onInput={ (e) => setData({...data,message:e.target.value})} name="message" placeholder="Message"
                                                    data-error="Ceci est un champ obligatoire."
                                                    required="required"></textarea>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <p class="form-message"></p>
                                        <div class="col-md-12">
                                            <div class="single-form">
                                                <button type="submit" className="main-btn">Envoyer {button}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="contact-address mt-30">
                            {contacts.map(c => (

                                <div class="single-address mb-30 border-bottom pb-15" key={c.id}>
                                    <div className="cont  w-100">
                                        {c.name && <h6 className="text-uppercase mb-10"> {c.name}</h6>}
                                        {c.phone && <p> <i className="fa fa-phone mr-10"></i> {c.phone}</p>}
                                        {c.email && <p> <i className="fa fa-envelope mr-10"></i>{c.email}</p>}
                                        {c.website && <p> <i className="fa fa-link mr-10"></i>{c.website}</p>}
                                    </div>
                                </div>
                                
                            ))}

                        </div>
                        <div class="map mt-30">
                            <div id="contact-map"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}
